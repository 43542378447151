.profile-container {
  /* background-color: var(--theme-color); */
  /* background-repeat: no-repeat; */
  height: 100%;
  width: auto;
  /* background-size: cover;
    background-position: center; */
  display: flex;
  justify-content: center;
}

.profile-center {
  position: relative;
  --color: hsl(var(--primary-dark-hsl), 0.7);
  background-color: var(--color);
  box-shadow: 0 0 15px 0 var(--color);
  /* padding: 0px 30px 25px; */
  width: 80%;
  border-radius: 20px;
  height: 80%;
  position: relative;
}

.p-tabview-nav-link {
  gap: 5px;
  border-left: 1px solid var(--theme-color) !important;
}

.row-profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px auto;
}

.row-profile-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px auto;
}

.row-profile p {
  margin-right: auto;
}

.left-align-profile {
  display: flex;
  text-align: left;
}

.card-gap {
  padding: 10px;
  gap: 10px;
  min-width: 100px !important;
}

.p-card-title {
  font-size: 1.5rem !important;
}

.dark-bg .p-dialog-header,
.dark-bg .p-dialog-footer,
.dark-bg .p-dialog-content {
  --color: hsl(var(--primary-dark-hsl), 0.7);
  background-color: var(--color) !important;
  box-shadow: 0 0 15px 0 var(--color) !important;
  padding: 50px 0 !important;
}

.dark-bg .p-dialog-header .p-dialog-title {
  color: white !important;
}

@media only screen and (max-width: 1000px) {
  /* .profile-center {
    height: 90vh;
    width: 80%;
  } */
}

@media only screen and (max-width: 500px) {
  .profile-center {
    width: 100%;
  }

  .p-dialog {
    width: 100% !important;
  }
}
