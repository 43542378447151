.sign-up-container{
    --color: hsl(var(--primary-dark-hsl), .8);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 30px;
    width: 500px;
    border-radius: 20px;
    height: 100%;
    top: 0;
    position: relative;
}

.sign-up-title{
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
}

.sign-up-form{
    position: relative;
    display: grid;
    gap: 0.5em;
}


.p-button{
    border: 1px solid #ffffff !important;
}

.sign-up-or{
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
}

.magicbtn{
    background-color: #574efc !important;
    color: #ffffff !important;
}

.facebookbtn{
    background-color: #3B5998 !important;
    align-items: center;
}

.googlebtn{
    background-color: #ec3a3a !important;
}

.px-3{
    color:white; 
    text-align: center;
    align-items: center;
    justify-content: center;
}


#email{
    color: white !important;
}