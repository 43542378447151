.columns {
  float: left;
  width: 33.3%;
  padding: 8px;
}

.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.price .header {
  background-color: #111;
  color: white;
  font-size: 25px;
}

.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

.price .grey {
  background-color: #eee;
  font-size: 20px;
}
.button {
  background-color: #04aa6d;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

.text-h1-background {
  width: 100%;
}

.text-group {
  width: 100%;
  margin: 10px auto;
}

.text-group p {
  width: 100%;
  margin: 0;
}

.pricing-card.card-bank {
  background-color: rgba(0, 63, 99, 255);
  /* background-color: rgba(240,243,250,255); */
  color: white;
}

.pricing-card.card-mobilePay {
  background-color: rgba(90, 120, 255, 255);
  color: white;
}

.p-card.pricing-card {
  width: 350px;
  padding: 24px 32px 0px;
  margin: 24px 24px;
  border-radius: 8px;
  min-height: 450px;
}

.price-card-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 100px;
}
.payment-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 3em;
}

.payment-method-section {
  background-color: #eee;
  padding: 15px;
}

.payment-method-section > * {
  text-align: center;
  color: black;
}

.pricing-card {
  background-color: #ffffff !important;
  max-width: 300px !important;
  height: 200px !important;
  padding: 0;
}

.price-text-box {
  max-width: 800px;
  padding: 1rem 2.25rem;
  background-color: #be7f00;
  border-radius: 10px 10px;
}
.price-text-box > p {
  font-size: 1.25rem;
}

.faq-div {
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  max-width: 800px;
}

/* .pricing-card.gradient-red{
  background: rgb(255,0,0);
  background: linear-gradient(135deg, rgba(255,0,0,1) 25%, rgba(168,40,20,0.9332326680672269) 70%);
  color: white;
} */

/* .pricing-card.gradient-red .p-card-subtitle, .pricing-card.gradient-green .p-card-subtitle, .pricing-card.gradient-yellow .p-card-subtitle{
  color: white;
} */

/* .pricing-card.gradient-green{
  background: rgb(27,199,0);
  background: linear-gradient(135deg, rgba(27,199,0,1) 25%, rgba(8,129,14,0.9332326680672269) 75%);
  color: white;
}

.pricing-card.gradient-yellow{
  background: rgb(218,194,0);
  background: linear-gradient(135deg, rgba(218,194,0,1) 25%, rgba(153,154,0,0.9332326680672269) 75%);
  color: white;
} */
