:root {
  --theme-color: rgb(25, 135, 203);
  --theme-color-gradient: linear-gradient(
    195deg,
    rgba(56, 210, 255, 1) 0%,
    rgba(25, 135, 203, 1) 100%
  );
  --tag-green: #1eca00;
  --tag-red: #ff2400;
  --tag-blue: #3b82f6;
  --tag-yellow: #ffcc00;
  --tag-orange: #fb8500;
}

html {
  height: 100%; /* Ensure the html element covers at least the viewport height */
  overflow-x: hidden;
}

body {
  position: relative; /* Needed for the absolute positioning of the ::before pseudo-element */
  margin: 0; /* Remove default margin */
  min-height: 100%; /* Ensure body at least covers the viewport, but can grow with content */
  overflow-x: inherit;
  overflow: hidden;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Ensure the gradient is behind the content */
  /* background: var(--theme-color-gradient); */
  background-color: rgb(23, 23, 23);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(39, 39, 39);
  font-family: "Alice";
}

p{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

span.p-float-label {
  color: black;
}

.full-screen-section {
  height: 100%;
}

.center-grid {
  display: grid;
  place-items: center;
  padding: 30px 0;
}

.width-100 {
  width: 100%;
}

.full-screen-section > * {
  text-align: center;
}

.half-screen-section {
  height: 50vh;
}

.resize-screen-section {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fixed-background {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  object-fit: cover; /* Cover the container without leaving black bars, may crop the video */
  z-index: -1;
  transform: translate(-50%, -50%) scale(2.5);
  filter: brightness(50%);
}

.center {
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}

.text-white {
  color: white;
}

.column-drop {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: auto 20px;
}

.flex-wrap {
  /* width: 900px;
  height: 400px; */
  justify-content: center;
  align-items: center;
}

.buttons-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}



.white-background {
  background-color: rgb(234, 234, 234);
}

.body-content {
  padding-top: 3.5rem; /* Adjust this value to match the height of your navbar */
}



.counter-center {
  display: table;
  margin: 0 auto;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  background-color: #2196f3;
  padding: 10px;
}

.red-background {
  background-color: rgb(255, 68, 68);
  height: 100%;
  max-width: 100%;
}

.blue-background {
  background: rgb(0, 81, 167);
  background: linear-gradient(
    180deg,
    rgba(0, 81, 167, 1) 90%,
    rgba(39, 85, 135, 1) 100%
  );
}

.blue2-background {
  background-color: rgb(39, 85, 135);
}

.green-background {
  background-color: rgb(0, 128, 0);
}

.yellow-background {
  background-color: rgba(175, 137, 21, 255);
}

.black-background {
  background-color: rgb(28, 28, 28);
}

.black-gradient-background {
  background: rgb(73, 73, 73);
  background: linear-gradient(
    345deg,
    rgba(73, 73, 73, 1) 3%,
    rgba(0, 0, 0, 1) 74%
  );
}

.error-msg {
  color: red;
  margin-bottom: 10px;
}

@media only screen and (max-width: 850px) {
  .camp-background {
    background-attachment: scroll; /* Fixed backgrounds can behave unpredictably on mobile, so you might use scroll */
    background-position: center 25%; /* Adjust this value as needed to shift the background image down */
  }
  /* .fixed-background {
    transform: scale(3);
    top: -5rem;
  } */
}

@media only screen and (max-width: 520px) {
  .fixed-background {
    top: 20rem;
    transform: scale(4);
  }
}
