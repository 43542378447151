.p-steps.p-component.steps{
    background-color: aliceblue;
}

.checkout-payment-box{
    background-color: aliceblue;
    padding: 20px;
    /* margin: 20px; */
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    max-width: 600px;
    min-height: 500px;
}

.checkout-payment-box p{
    font-size: 1.25rem;
    font-weight: bold;
}