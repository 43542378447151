.about-container p {
    text-align: justify;
}

.about-h2 {
  text-align: center;
  color: white;
  font-size: 2.25em;
}

.about-h1 {
  text-align: center;
  color: white;
  font-size: 3.5em;
  padding: 50px 0;
}
