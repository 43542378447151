.display-container {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Ensure all items stretch to the same height */
  flex-wrap: wrap;
  gap: 10px;
}

.display-card {
  max-width: 300px;
  flex: 1 1 250px; /* Ensure all cards have the same width */
  min-height: 500px;
}

.display-card-overflow {
  overflow-y: auto;
  max-height: 500px;
}

.display-card ul {
  list-style-type: decimal;
  display: grid;
  grid-auto-rows: minmax(0, auto);
  gap: 10px; /* Adjust gap as needed */
}

.display-card ul li {
  word-wrap: break-word;
}

.display-header {
  margin: 0;
  font-weight: bolder;
}