.camp-background {
  background-image: url("../images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  /* filter: contrast(60%); */
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: -1;
  /* Animation */
  animation: brightnessAnimation 3.5s ease-out forwards; /* Adjust the duration as needed */
}

.bg-black-gradient-grid {
  background-image: linear-gradient(90deg, rgb(33, 33, 33) 0%, transparent 59%),
    repeating-linear-gradient(
      45deg,
      rgba(96, 96, 96, 0.1) 0px,
      rgba(96, 96, 96, 0.1) 1px,
      transparent 1px,
      transparent 13px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(96, 96, 96, 0.1) 0px,
      rgba(96, 96, 96, 0.1) 1px,
      transparent 1px,
      transparent 13px
    ),
    linear-gradient(90deg, rgb(33, 33, 33), rgb(33, 33, 33));
}

.bg-black-gradient-grid-2 {
  background-image: radial-gradient(
      circle at center center,
      transparent,
      rgb(33, 33, 33)
    ),
    repeating-linear-gradient(
      135deg,
      rgb(33, 33, 33) 0px,
      rgb(33, 33, 33) 2px,
      transparent 2px,
      transparent 10px,
      rgb(33, 33, 33) 10px,
      rgb(33, 33, 33) 11px,
      transparent 11px,
      transparent 21px
    ),
    repeating-linear-gradient(
      45deg,
      rgb(47, 47, 47) 0px,
      rgb(47, 47, 47) 4px,
      transparent 4px,
      transparent 8px
    ),
    linear-gradient(90deg, rgb(33, 33, 33), rgb(33, 33, 33));
}

.bg-blue-complex-gradient-1 {
  background-image: linear-gradient(
      41deg,
      rgba(107, 107, 107, 0.04) 0%,
      rgba(107, 107, 107, 0.04) 8%,
      rgba(31, 31, 31, 0.04) 8%,
      rgba(31, 31, 31, 0.04) 100%
    ),
    linear-gradient(
      9deg,
      rgba(228, 228, 228, 0.04) 0%,
      rgba(228, 228, 228, 0.04) 62%,
      rgba(54, 54, 54, 0.04) 62%,
      rgba(54, 54, 54, 0.04) 100%
    ),
    linear-gradient(
      124deg,
      rgba(18, 18, 18, 0.04) 0%,
      rgba(18, 18, 18, 0.04) 37%,
      rgba(233, 233, 233, 0.04) 37%,
      rgba(233, 233, 233, 0.04) 100%
    ),
    linear-gradient(
      253deg,
      rgba(201, 201, 201, 0.04) 0%,
      rgba(201, 201, 201, 0.04) 55%,
      rgba(47, 47, 47, 0.04) 55%,
      rgba(47, 47, 47, 0.04) 100%
    ),
    linear-gradient(
      270deg,
      rgba(172, 172, 172, 0.04) 0%,
      rgba(172, 172, 172, 0.04) 33%,
      rgba(26, 26, 26, 0.04) 33%,
      rgba(26, 26, 26, 0.04) 100%
    ),
    linear-gradient(
      64deg,
      rgba(11, 11, 11, 0.04) 0%,
      rgba(11, 11, 11, 0.04) 38%,
      rgba(87, 87, 87, 0.04) 38%,
      rgba(87, 87, 87, 0.04) 100%
    ),
    linear-gradient(
      347deg,
      rgba(199, 199, 199, 0.04) 0%,
      rgba(199, 199, 199, 0.04) 69%,
      rgba(4, 4, 4, 0.04) 69%,
      rgba(4, 4, 4, 0.04) 100%
    ),
    linear-gradient(
      313deg,
      rgba(36, 36, 36, 0.04) 0%,
      rgba(36, 36, 36, 0.04) 20%,
      rgba(91, 91, 91, 0.04) 20%,
      rgba(91, 91, 91, 0.04) 100%
    ),
    linear-gradient(90deg, rgb(31, 47, 118), rgb(35, 148, 228));
}

.bg-green-blue-gradient {
  background-image: linear-gradient(
      66deg,
      rgba(37, 37, 37, 0.05) 0%,
      rgba(37, 37, 37, 0.05) 33.333%,
      rgba(89, 89, 89, 0.05) 33.333%,
      rgba(89, 89, 89, 0.05) 66.666%,
      rgba(234, 234, 234, 0.05) 66.666%,
      rgba(234, 234, 234, 0.05) 99.999%
    ),
    linear-gradient(
      130deg,
      rgba(104, 104, 104, 0.05) 0%,
      rgba(104, 104, 104, 0.05) 33.333%,
      rgba(246, 246, 246, 0.05) 33.333%,
      rgba(246, 246, 246, 0.05) 66.666%,
      rgba(178, 178, 178, 0.05) 66.666%,
      rgba(178, 178, 178, 0.05) 99.999%
    ),
    linear-gradient(
      72deg,
      rgba(168, 168, 168, 0.05) 0%,
      rgba(168, 168, 168, 0.05) 33.333%,
      rgba(73, 73, 73, 0.05) 33.333%,
      rgba(73, 73, 73, 0.05) 66.666%,
      rgba(253, 253, 253, 0.05) 66.666%,
      rgba(253, 253, 253, 0.05) 99.999%
    ),
    linear-gradient(
      139deg,
      rgba(241, 241, 241, 0.05) 0%,
      rgba(241, 241, 241, 0.05) 33.333%,
      rgba(109, 109, 109, 0.05) 33.333%,
      rgba(109, 109, 109, 0.05) 66.666%,
      rgba(100, 100, 100, 0.05) 66.666%,
      rgba(100, 100, 100, 0.05) 99.999%
    ),
    linear-gradient(
      111deg,
      rgba(65, 65, 65, 0.05) 0%,
      rgba(65, 65, 65, 0.05) 33.333%,
      rgba(223, 223, 223, 0.05) 33.333%,
      rgba(223, 223, 223, 0.05) 66.666%,
      rgba(50, 50, 50, 0.05) 66.666%,
      rgba(50, 50, 50, 0.05) 99.999%
    ),
    linear-gradient(90deg, rgb(41, 89, 88), rgb(75, 98, 201));
}

.bg-confetti {
  background-image: repeating-linear-gradient(
      157.5deg,
      rgb(0, 0, 0) 0px,
      rgb(0, 0, 0) 10px,
      transparent 10px,
      transparent 11px
    ),
    repeating-linear-gradient(
      67.5deg,
      rgb(0, 0, 0) 0px,
      rgb(0, 0, 0) 10px,
      transparent 10px,
      transparent 11px
    ),
    linear-gradient(
      90deg,
      hsl(143, 33%, 50%),
      hsl(215, 33%, 50%),
      hsl(287, 33%, 50%),
      hsl(359, 33%, 50%),
      hsl(71, 33%, 50%)
    );
}


.bg-rotate {
  background-size: 400% 400%;
  animation: backgroundRotate 120s linear infinite;
}
@keyframes backgroundRotate {
  from {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0% 0%;
  }
}

@keyframes brightnessAnimation {
  from {
    filter: brightness(70%);
  }
  to {
    filter: brightness(30%);
  }
}

@media only screen and (max-width: 850px) {
  .camp-background {
    background-attachment: scroll; /* Fixed backgrounds can behave unpredictably on mobile, so you might use scroll */
    background-position: center 25%; /* Adjust this value as needed to shift the background image down */
  }
  /* .fixed-background {
    transform: scale(3);
    top: -5rem;
  } */
}
