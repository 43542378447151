.screen-full-height {
  height: 100vh;
  overflow-y: hidden;
}

.screen-80-height {
  min-height: 80vh;
  overflow-y: hidden;
}

.screen-50-height {
  min-height: 50vh;
  overflow-y: hidden;
}