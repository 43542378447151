.ptext-magic-link{
    color: white;
    text-align: left;

}

.backbtn{
    background-color: rgb(255, 114, 26) !important;
    color: #ffffff !important;
}

/*    background-color: #574efc !important; */
