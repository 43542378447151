/* https://github.com/WebDevSimplified/transparent-login-form/blob/main/styles.css */

/* *, *::before, *::after {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
} */

:root {
  --primary-light-hsl: 200, 100%, 91%;
  --primary-hsl: 141, 70%, 38%;
  --primary-dark-hsl: 200, 100%, 6%;
  --success-hsl: 100, 60%, 50%;
  --error-hsl: 0, 60%, 50%;
}

.full-screen-container {
  background-image: url("../images/background.jpg");
  min-height: 100vh;
  width: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.login-container {
  --color: hsl(var(--primary-dark-hsl), 0.7);
  background-color: var(--color);
  box-shadow: 0 0 15px 0 var(--color);
  padding: 10px 50px 30px;
  min-width: 400px;
  border-radius: 20px;
  height: auto;
  position: relative;
}

.login-title {
  position: relative;
  top: 2rem;
  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
}

.login-provider-form{
  position: relative;
  display: grid;
  gap: 1rem;
}

button.p-button.p-component.login-button.p-button-raised.p-button-rounded{
  color: rgb(255, 255, 255);
  border: 1px solid white !important;
  text-transform: uppercase;
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  color: black;
}

.input-group,
.form-info {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.input-group label,
.form-info label {
  color: white;
  font-weight: lighter;
}

.input-group input,
.form-info input {
  font-size: 1.25rem;
  padding: 0.5em;
  background-color: hsl(var(--primary-light-hsl), 0.3);
  border: none;
  outline: none;
  border-radius: 0.25em;
  color: white;
  font-weight: lighter;
}

.input-group.success input {
  box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.input-group.error input {
  box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.input-group input:focus {
  box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.input-group .msg {
  display: none;
  font-size: 0.75rem;
}

.input-group.success .msg,
.input-group.error .msg {
  display: block;
}

.input-group.error .msg {
  color: hsl(var(--error-hsl));
  padding: 10px 0;
  text-decoration: underline;
}

.input-group.success .msg {
  color: hsl(var(--success-hsl));
}

.or {
  color: white;
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
  margin: 20px 0;
}

.block {
  display: block;
  margin: 2rem auto;
}

#firstName,
#lastName {
  width: 50%;
  color: black;
}

#birthday {
  width: 100%;
}

.list {
  width: 75%;
  height: 100%;
}

.list:hover,
.list:focus {
  background-color: aliceblue;
  filter: brightness(95%);
  color: black;
}

/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_social_login */

/* style inputs and link buttons */
input,
.butn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  /* opacity: 0.75; */
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
}

input:hover,
.butn:hover {
  filter: brightness(85%);
  color: rgb(0, 0, 0);
}


/* add appropriate colors to fb, twitter and google buttons */
.fb {
  background-color: #3b5998;
  color: white;
  text-align: center;
}

.google {
  background-color: #dd4b39;
  color: white;
  text-align: center;
}
