.loading-section {
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: auto;
}
.loading-section p {
  font-size: 2rem;
  color: black;
  text-align: center;
}

.loading-box {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.loading-box p {
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.loading-fixed {
  position: absolute;
  display: grid;
  justify-content: center;
  align-items: center;
  height: inherit;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 100;
}

.loading-bg-blue {
  background-color: rgb(31, 118, 158);
}

.loading-module {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
}
