footer {
  background-color: #333;
  color: white;
  padding: 10px 0 0 ;
  width: 100%;
  z-index: 100;
  justify-content: center;
}

footer h2 {
  color: whitesmoke;
}

footer > .box {
  margin: 1em 5em;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 2em;
}

footer ul {
  padding-left: 0;
}

footer .box-container {
  background-color: #333;
  width: 100%;
  justify-content: center;
}

footer .box-container ul li {
  list-style-type: none;
}

footer .box-container ul li li {
  list-style-type: circle;
}

.box a {
  color: white;
  text-decoration: none;
}

ul.ref-links {
  padding-left: 1em;
}

footer .box a:hover {
  color: #f1c40f;
}

footer a {
  padding: 0;
  margin: 0;
}

.social-ref {
  margin: 0.75em auto 1.25em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color:#f1c40f;
  gap: 0.75em;
}

.social-ref a {
  width: auto;
}

.social-ref img {
  width: 2em;
  transition: transform .25s; /* Smooth transition on hover and unhover */
}

ul.list-space  li {
  margin: 0.5rem 0;
}

/* when hover over img i want it to be litte bigger */
/* Can you add a 1 second delay on the hover effect? */
/* Also when you unhover that it will give the same effect but in reverse */
.social-ref img:hover {
  transform: scale(1.2);
}

footer .copy-right {
  background-color: #141414;
  color: white;
  text-align: center;
  width: 100%;
  padding: 1.5em 0;
  margin:0;
}

footer .copy-right p {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-style: italic;

}



@media (max-width: 768px) {
  footer .box {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }
}
