section.section {
  width: 100%;
}
.section-text {
  color: white;
  text-align: center;
}

section.section {
  padding: 2rem 0;
}

.section-box {
  margin: 2rem 20px;
}

.section-div {
  display: grid;
  place-items: center;
}

.section-div > * {
  color: white;
  padding: 10px 18rem;
  font-size: 1.2rem;
}

h2.section-h2 {
  margin: 0;
}
h3.section-h2 {
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 1080px) {
  .section-div > * {
    color: white;
    padding: 10px 50px;
    font-size: 1rem;
  }
}
