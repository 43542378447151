.p-progressbar-label {
  display: block !important;
  position: absolute !important;
  width: 700px !important;
  text-align: center !important;
  font-size: 1em !important;
}

.p-steps .p-steps-item .p-menuitem-link {
  background-color: transparent !important;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  color: #484848 !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: #ffffff !important;
}

.checkout-box > * {
  background-color: #ffffff !important;
  color: #484848 !important;
  border-radius: 10px !important;
  padding: 20px !important;
  margin: 20px 0 !important;
  height: 100%;
  max-width: 600px !important;
}

.p-black {
  font-size: "Alice";
  color: #484848 !important;
}

.p-result {
  font-weight: bold !important;
  font-size: 1.5em;
}

.align-horizontal-anonymous {
  display: flex;
  justify-content: left;
  align-items: left;
  color: #ffffff !important;
  gap: 10px;
  padding: 10px;
}

.align-horizontal-payment {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 400px;
  margin: 50px 50px 20px;
}

.align-horizontal-payment h1 {
  color: #ffffff !important;
}
/* .payment-choice {
  display: grid;
  gap: 210px;
} */

.payment-notes {
  color: #ffffff !important;
  font-size: 1rem;
  text-align: justify;
}

.paymentButton {
  border-radius: 10px !important;
  padding: 20px !important;
  margin: 20px 0 !important;
  height: 100%;
  min-width: 300px !important;
  max-width: 400px;
  font-size: 1.24em !important;
}

/* This class is for the outermost container, ensuring it takes full width */
.column-drop {
  display: flex;
  flex-wrap: wrap;
  width: 100%; /* Ensures the container takes the full width of its parent */
}

.payment-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

@media only screen and (max-width: 850px) {
  .payment-div {
    flex-direction: column;
  }
  .align-horizontal-payment {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 400px;
    margin: 0;
  }
}
