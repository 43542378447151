.menu-bar-container {
  z-index: -100;
  width: 100%;
  visibility: hidden;
}

.menubar {
  display: flex;
  z-index: 100;
  width: 100%;
}

.menubar > .p-menubar.p-component {
  width: inherit;
  z-index: inherit;
  position: fixed;
}

.menubar a.logging {
  margin-left: auto;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.menubar a.logging:hover {
  color: #3f78ff;
}



.menubar div.logging-container {
  display: grid;
  grid-auto-flow: column; /* Ensures items flow into columns */
  gap: 1rem;
  margin-right: 1rem;
}
