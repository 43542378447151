.payment-choice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 93.2vh; /* This will make the container take at least the full height of the viewport */
  background-image: linear-gradient(
      242deg,
      rgba(19, 19, 19, 0.04) 0%,
      rgba(19, 19, 19, 0.04) 65%,
      rgba(130, 130, 130, 0.04) 65%,
      rgba(130, 130, 130, 0.04) 100%
    ),
    linear-gradient(
      49deg,
      rgba(193, 193, 193, 0.04) 0%,
      rgba(193, 193, 193, 0.04) 72%,
      rgba(136, 136, 136, 0.04) 72%,
      rgba(136, 136, 136, 0.04) 100%
    ),
    linear-gradient(
      78deg,
      rgba(37, 37, 37, 0.04) 0%,
      rgba(37, 37, 37, 0.04) 90%,
      rgba(40, 40, 40, 0.04) 90%,
      rgba(40, 40, 40, 0.04) 100%
    ),
    linear-gradient(
      310deg,
      rgba(233, 233, 233, 0.04) 0%,
      rgba(233, 233, 233, 0.04) 25%,
      rgba(47, 47, 47, 0.04) 25%,
      rgba(47, 47, 47, 0.04) 100%
    ),
    linear-gradient(90deg, rgb(10, 179, 203), rgb(52, 104, 240));
  color: white; /* This will apply to all text inside the container, including h1 and h2 */
  gap: 20px; /* This adds space between each item */
  overflow-y: hidden;
}

.payment-choice-button {
  margin: 10px 0; /* This ensures buttons are spaced out vertically */
}

/* Directly target h1 and h2 if you need specific styles different from the container's text color */
.payment-choice-container h1 {
  color: white; /* Ensures h1 and h2 are white */
}
.payment-choice-container h2 {
  background-color: rgb(239, 23, 23);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
}

.payment-choice-container p {
  font-size: 1.1rem; /* This will apπply to all paragraphs inside the container */
  background-color: rgb(239, 23, 23);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  max-width: 500px;
  margin: 0.5rem 1rem
}
