.btn-delete {
  background-color: rgb(255, 26, 26) !important;
}
.btn-delete:hover {
  background-color: rgb(176, 0, 0) !important;
}

.btn-edit {
  background-color: rgb(15, 97, 185) !important;
}

.btn-right {
  float: right;
}

/* 
  .modula-content .btn-exit{
    text-align: right !important;
  } */

.btn-exit {
  background-color: rgb(255, 26, 26) !important;
  text-align: right !important;
  position: relative !important;
  width: 40px !important;
  height: 40px !important;
  padding: 1.5rem !important;
}

.btn-single-space {
  padding: 10px !important;
}

/*Excel button*/
.btn-excel {
  background-color: #28a745 !important;
  border-radius: 10px !important;
}
.btn-excel:hover {
  background-color: #0a7323 !important;
}

/*PDF button*/
.btn-pdf {
  background-color: #f97316 !important;
  border-radius: 10px !important;
}
.btn-pdf:hover {
  background-color: #e66000 !important;
}


.p-button {
  position: static !important;
}

button.prime {
  background-color: #007bff !important;
  border-color: hsl(211, 100%, 78%) !important;
}

button.prime:hover {
  background-color: #0056b3 !important;
  border-color: #0056b3 !important;
}


.menu-bar-btn-black {
  background-color: #000 !important;
  color: #fff !important;
}