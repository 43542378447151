.flags-box p{
    padding: 10px
}

.flag-text{
    padding: 10px 0;
    margin: 0;
}

.box-row.flag-box{
gap: 5px;
}