.text-white,
.text-white > p {
  color: white;
}

.text-black {
  color: black;
}

.text-red {
  color: rgb(255, 37, 37);
}

.container-title {
  position: relative;
  top: 0.75em;
  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
  padding-bottom: 10px;
}

.normal-text {
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
  font-size: 18px;
  line-height: 1.5;
  padding: 20px 20px;
  font-weight: 800;
  font-style: oblique;
}

.text-h1 {
  position: relative;
  color: white;
  font-size: 64px;
  text-align: center;
  padding-top: 5%;
}

.section-subtitle {
  color: white;
  font-size: 32px;
  text-align: center;
}

.left-align {
  min-height: 100vh;
  padding: 0 12em;
}

.left-align p {
  color: white;
  font-size: 1.5vw;
  line-height: 1.5;
  padding: 20px 0;
}

.left-align > p:first-child::first-letter {
  position: relative;
  font-size: 4em;
  font-weight: 700;
  line-height: 70px;

  /* float: left; */
  /* margin-right: 0.05em;
    padding: 0.05em; */
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.left-align > p:last-child {
  padding-bottom: 50px;
  margin-bottom: 0;
}

.small-padding {
  padding: 5px;
  margin: 0%;
}

.text-no-space {
  margin: 0;
  padding: 0;
}

.navigate-text,
.navigate-text > * {
  position: relative;
  font-size: 0.8em;
  text-align: right;
  color: rgb(0, 174, 255);
  text-decoration: underline;
  cursor: pointer;
}

.navigate-text-left,
.navigate-text-left a {
  position: relative;
  text-align: left;
  color: rgb(0, 174, 255) !important;
  text-decoration: underline;
}

.navigate-text a,
.navigate-text-left a {
  color: rgb(0, 174, 255) !important;
  cursor: pointer;
}

.text-medium {
  font-size: 1.5em;
  font-weight: 700;
}

.text-word-wrap {
  word-wrap: break-word;
}


.text-title-responsive {
  font-size: 3rem;
}

.text-subtitle-responsive {
  font-size: 1.75rem;
}



a.a-remove > * {
  text-decoration: none;
  color: white;
}

.text-warning-container {
  background-color: #ff2600;
  border-radius: 20px;
  color: #fff;
  padding: 10px 20px;
  max-width: 340px;
}

.text-warning-container a {
  color: rgb(255, 255, 255);
}

.text-warning-container a:hover {
  color: rgb(232, 235, 242);
}

.text-oval {
  border-radius: 20px;
  padding: 10px 10px !important;
}


@media screen and (max-width: 850px) {
  .left-align p {
    font-size: 16px;
  }

  .left-align {
    padding: 20px 2em;
    margin: 0;
  }

  .left-align > p:last-child {
    /* padding-bottom: 100px; */
  }
}
