.form-screen-container {
  background-color: #0b3ba2;
  min-height: 100vh;
  min-width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.form-container {
  --color: hsl(var(--primary-dark-hsl), 0.7);
  background-color: var(--color);
  box-shadow: 0 0 15px 0 var(--color);
  padding: 0px 30px 25px;
  max-width: 500px;
  min-width: 400px;
  border-radius: 20px;
  height: auto;
  margin-top: 0;
  top: 0;
  position: relative;
}

.form-title {
  position: relative;
  top: 2rem;
  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
}

.center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoBg {
  position: fixed;
  top: 0;
  width: auto;
  min-height: 100%;
  /* filter: blur(2.5px); */
  /* background-position-y: center; */
  background-size: cover;
  z-index: -1;
}

.fb {
  background-color: #3b5998;
  color: white;
}
.fb:hover {
  background-color: #274381;
}

.google {
  background-color: #ffffff;
  color: rgb(59, 58, 58);
}

.btn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  /* opacity: 0.85; */
  display: block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
}

.form {
  top: 50px;
}

.float-text {
  color: black;
}

.p-highlight .p-button-label {
  color: white !important;
}

.member-boolean-txt {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}

.checkbox-text {
  color: #fff;
  font-size: 15px;
  margin-left: 10px;
  align-items: center;
}

.form-submit-button {
  font-size: 1.5rem;
  font-weight: lighter;
  color: white;
  height: 50px;
  font-weight: 400;
  background-color: hsl(var(--primary-hsl), 0.85);
  border: 1px solid hsl(var(--primary-hsl));
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  transition: all 1.2s ease-in-out;
}

.form-submit-button:hover,
.form-submit-button:focus {
  background-color: hsl(var(--primary-hsl), 0.55);
}

.checkbox-div {
  display: flex;
  margin-top: 5px;
}

.center-selection {
  display: grid !important;
  row-gap: 10px;
}

.gap-selection > * {
  margin: 0 10px !important;
}

.gender-selection {
  margin: 20px 0;
}

.div-birthday > * {
  width: 100%;
}

.hint-birthday {
  color: #ffe14e;
  font-size: 12px;
  margin-bottom: 5px;
}

.accomodation-info-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.white-text > * {
  color: white;
}

.succesful-text {
  color: rgb(0, 0, 0);
  font-size: 1.2em;
  margin: 20px 0;
}


.p-button.success-button{
  background-color: #22c55e
}

.p-button.success-button:hover{
  background-color: #0fae49 !important
}

.p-button.error-button{
  background-color: #ff4d4f
}

.p-button.error-button:hover{
  background-color: #d93638 !important
}

.p-button.loading-button{
  background-color: #f97316
}