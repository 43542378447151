.p-card.p-component.team-card {
  /* center text */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px auto;
  text-align: center;
}

.p-accordion .p-accordion-content.teamTab {
  padding: 0 !important;
}

.p-tabview .p-tabview-panels.teamTab {
  padding: 0 !important;
}

.tab-blue .p-card.p-component.card-blue,
.tab-blue .p-accordion-header-link,
.tab-blue .p-accordion-header-text,
.tab-blue .p-accordion-header-link .p-accordion-toggle-icon {
  background-color: #4169e1 !important;
  color: #fff;
}

.tab-red .p-card.p-component.card-red,
.tab-red .p-accordion-header-link,
.tab-red .p-accordion-header-text,
.tab-red .p-accordion-header-link .p-accordion-toggle-icon {
  background-color: #ff2400 !important;
  color: #fff;
}

.tab-purple .p-card.p-component.card-purple,
.tab-purple .p-accordion-header-link,
.tab-purple .p-accordion-header-text,
.tab-purple .p-accordion-header-link .p-accordion-toggle-icon {
  background-color: #6a5acd !important;
  color: #fff;
}

/* .p-card.p-component.card-green {
    background-color: #27ed04 !important;
    color: #000000 !important;
    border: 1px solid #000 !important;
  } */

.tab-pink .p-card.p-component.card-pink,
.tab-pink .p-accordion-header-link,
.tab-pink .p-accordion-header-text,
.tab-pink .p-accordion-header-link .p-accordion-toggle-icon {
  background-color: #f78fa7 !important;
  color: #fff !important;
}

.tab-goldfinch .p-card.p-component.card-goldfinch,
.tab-goldfinch .p-accordion-header-link,
.tab-goldfinch .p-accordion-header-text,
.tab-goldfinch .p-accordion-header-link .p-accordion-toggle-icon {
  background-color: #daa520 !important;
  color: #fff;
}

.tab-brown .p-card.p-component.card-brown,
.tab-brown .p-accordion-header-link,
.tab-brown .p-accordion-header-text,
.tab-brown .p-accordion-header-link .p-accordion-toggle-icon {
  background-color: #3d3635 !important;
  color: #fff;
}

.card-p-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* align items horizontally to center */
  /* align items vertically to center */
  align-items: center;
  margin: 10px;
  gap: 50px;
  font-size: 24px;
}

.p-leader {
  width: 100%;
}
.p-member {
  padding: 20px;
  min-width: 250px;
  margin: 0px;
}

.team-color-picker {
  width: 50px;
  height: 50px;
}

.column-box {
  border: #3d3635 1px solid;
  border-radius: 10px;
}

/*mobile*/
@media only screen and (max-width: 600px) {
  .p-leader {
    width: 100%;
  }
  .p-member {
    padding: 10px;
    width: 100%;
  }
}
