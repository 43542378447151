.black-box {
  --color: hsl(var(--primary-dark-hsl), 0.7);
  background-color: var(--color);
  box-shadow: 0 0 15px 0 var(--color);
  padding: 30px 30px;
  width: 500px;
  border-radius: 20px;
  top: 0;
  position: relative;
}

.box-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 11px;
  padding: 20px;
}

.gap-20 {
  gap: 20px;
}

.box-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.box-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.box-responsive {
  max-width: 400px;
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.box-full-height {
  height: 100vh;
  overflow-y: hidden;
}

.box-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px 0;
}

.box-width-fit {
  min-width: 500px;
  padding: 30px;
}

.modula-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modula-content {
  background-color: hsl(var(--primary-dark-hsl), 0.9);
  position: relative;
  display: grid;
  /* flex-direction: column; */
  /* justify-content: center;
    align-items: center; */
  width: 600px;
  box-sizing: border-box;
  color: #fff;
  border: #fff 1px solid;
  border-radius: 10px;
  z-index: 1;
}

.modula-header {
  display: flex;
  background-color: inherit;
  color: #000;
  z-index: 1;
  margin: 1.5rem;
}

.modula-header h1 {
  font-family: "Arial";
  color: aliceblue;
  flex-grow: 1;
  margin: 0;
  font-size: 1.75rem;
}

.modula-body p,
.modula-body a {
  color: aliceblue;
  margin: 10px 0;
}

p.note {
  color: gold;
}

.modula-body label {
  text-align: left;
  color: aliceblue;
  margin: 10px 0;
}

.modula-body {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 1.5rem 1.5rem;
  color: rgba(0, 0, 0, 0.5);
}

.box-space-horizontal {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.box-width {
  max-width: 600px;
  padding: 30px;
  margin: 0 auto;
}

.box-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-white {
  background-color: #fff;
  box-shadow: 0 0 5px 0 #fff;
  color: white;
  padding: 10px;
  border-color: #000;
  width: auto;
  border-radius: 20px;
  position: relative;
}

.box-padding {
  padding: 2rem;
}



.box-center-grid {
  display: grid !important;
  place-items: center !important;
}

@media screen and (max-width: 850px) {
  .modula-content {
    width: 100%;
  }
}
