.admin-tab {
  /* position: relative;
  --color: hsl(var(--primary-dark-hsl), 0.7);
  background-color: var(--color);
  box-shadow: 0 0 15px 0 var(--color);
  width: auto !important;
  border-radius: 20px;
  height: 100%;
  position: relative; */
}

.admin-tab .p-tabview-panels {
  padding: 0 !important;
}

/* .p-datatable-wrapper{
    width: 100% !important;
} */

.p-column-title {
  min-width: 150px !important;
}
/* .p-sortable-column.p-resizable-column{
    min-width: 120px !important;
} */

.admin-container {
  /* background-image: url("../images/background.jpg"); */
  /* background-color: var(--theme-color);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center; */
}

.center-text-header {
  text-align: center;
  font-size: 2vw;
}

section.tab-content {
  padding: 1.5em;
}

div.center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px auto;
}

/* sticky the row header of name in the table */
.p-datatable.p-component.p-datatable-selectable.p-datatable-resizable.p-datatable-resizable-fit.p-datatable-scrollable.p-datatable-scrollable-vertical.p-datatable-responsive-scroll.p-datatable-striped.p-datatable-gridlines.p-datatable-sm.admin-list-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr .p-sortable-column.p-resizable-column.p-frozen-column{
  position: sticky;
}

@media screen and (max-width: 800px) {
  .center-text-header {
    font-size: 4vw;
  }
}
