.page-box {
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 30px;
}



.page-container p.subtitle {
  color: #b7b7b7 !important;
  text-align: center;
  font-style: italic;
  font-size: 1rem;
  margin: 0;
}

div.page-box {
  max-width: 600px;
  padding: 2.25rem 2rem;
}

section.page-text-white p,
section.page-text-white li {
  font-size: 1.1rem;
  line-height: 1.5;
}

section.page-text-white h2,
section.page-text-white p {
  margin: 1rem auto 0rem;
}

.page-text-white * {
  font-family: "Times New Roman", Times, serif;
  color: white;
}


section.page-container h1 {
  position: relative;
  text-align: center;
  font-size: 3.25rem;
  margin: 0;
}


section.page-container {
  margin: 0 0;
}

.page div.box {
  max-width: 800px;
  padding: 1.5rem;
}

.page a {
  color: #3f78ff;
  text-decoration: none;
}

.page a:hover {
  color: #3f78ff;
  text-decoration: underline;
}

.page a:visited {
  color: #af8dff;
}

.page p.bold {
  font-weight: bold;
}

.page h3 {
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0;
}

.page p.margin-top-0 {
  margin-top: 0.25rem;
}

div.page-box ul{
  margin-top: 0;
}


@media screen and (max-width: 800px) {
  .page-box {
    margin: 0;
  }
}
