.tag-blue,
.tag-yellow,
.tag-green,
.tag-orange,
.tag-red,
.tag-purple {
  font-size: 1.5rem !important;
  border: 1px solid #ccc;
  padding: 0.25em 0.5em;
  border-radius: 0.5em;
  margin-right: 0.5em;
}

.tag-small {
  font-size: 0.75rem !important;
}

.tag-green {
  background-color: var(--tag-green) !important;
  color: #ffffff !important;
}

.tag-red {
  background-color: var(--tag-red) !important;
  color: #ffffff !important;
}

.tag-blue {
  background-color: var(--tag-blue) !important;
  color: #ffffff !important;
}

.tag-orange {
  background-color: var(--tag-orange) !important;
  color: #ffffff !important;
}

.tag-yellow {
  background-color: var(--tag-yellow) !important;
  color: #ffffff !important;
}

.tag-purple {
  background-color: #6a5acd !important;
  color: #fff !important;
}
